import { Building2, CircleHelp, Mail, Phone } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { Col, Row } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const SignUp = () => {

    const [loading, setLoading] = useState(false);


    const notify = (type) => {
        let message;
        if (type === 'error') {
            message = "Signup failed. Please try again.";
        } else if (type === 'success') {
            message = "Account created successfully!";
        } else if (type === 'warning') {
            message = "Account already exists!";
        }
        const backgroundColor = 'rgb(1, 27, 69)';
        const progressColor = 'linear-gradient(240deg, rgba(0, 184, 199, 0.1), rgba(1, 27, 69, 0.1))'; // Keep the same progress style
        const toastType = type === 'error' ? toast.error : type === 'success' ? toast.success : toast.warn;

        toastType(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                background: backgroundColor, // Dynamic background color
                color: '#fff', // White text
                borderRadius: '8px', // Rounded corners
                fontSize: '16px', // Adjust font size if needed
                padding: '12px 20px', // Adjust padding
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
            progressStyle: {
                background: progressColor, // Same gradient progress bar
            },
        });
    };

    const navigate = useNavigate();

    // Validation Schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid Email Address")
            .required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .matches(/\d/, "Password must contain at least one number")
            .required("Password is required"),
        // phone: Yup.string()
        //     .matches(/^[0-9]/, "Phone number must between 7 to 15 digits")
        //     .required("Phone number is required").min(7).max(15),
        company: Yup.string()
            .min(3, "Company name must be at least 3 characters")
            .required("Company name is required")
    });

    // Initial Values
    const initialValues = {
        email: '',
        password: '',
        // phone: '',
        company: ''
    };

    // Submit Function
    const submit = async (values, { resetForm }) => {
        try {
            setLoading(true);

            const encodedPassword = btoa(values.password);

            const payload = {
                customer_email: values.email,
                password: encodedPassword,
                // phone_number: values.phone,
                company_name: values.company,
            };

            const extendedPayload = {
                ...payload,
                carriers: [
                    {
                        carriername: "n/a",
                        username: "n/a",
                        password: "n/a"
                    }
                ]
            };

            const response = await fetch('https://smart.lateshipment.com/Genericwebhook/accountcreationapi.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Key': process.env.REACT_APP_X_Auth_Key,
                    'X-Auth-email': process.env.REACT_APP_X_Auth_email,
                },
                body: JSON.stringify(extendedPayload),
            });

            if (!response.ok) {
                throw new Error('Account creation failed');
            }

            const result = await response.json();

            if (result.status === 'success') {
                if (result.message.includes('Account Created')) {
                    localStorage.setItem('signupPayload', JSON.stringify(payload));
                    notify('success');
                    resetForm();
                    setTimeout(() => {
                        navigate('/courier');
                    }, 3000);
                } else if (result.message.includes('Already Have Account')) {
                    notify('warning');
                } else {
                    throw new Error('Unexpected success message');
                }
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error("Error during signup:", error);
            notify('error');
        } finally {
            setLoading(false);  // Reset loading after request completion
        }
    };


    // Formik Hook
    const { handleBlur, handleChange, handleSubmit, touched, errors, values } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submit
    });

    return (
        <React.Fragment>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className='login_par'>
                <Row className='m-0 h-100 align-items-center justify-content-center'>
                    <Col xxl={8} xl={9} lg={10} md={6} sm={8} className='shadow-lg rounded-4 p-0 overflow-hidden'>
                        <Row className='m-0'>
                            <Col lg={7} className='d-lg-flex d-none p-0 theme_bg py-5 align-items-center'>
                                <div className='w-100 my-auto'>
                                    <div className='img_box bg-white text-center mx-auto rounded-5 py-5 px-5'>
                                        <img src={require("../../Images/login_front.png")} className='w-100 cover' alt="" />
                                    </div>
                                    <div className='txt mt-5'>
                                        <p className='m-0 text-white text-center fw-bold f_18'>
                                            Shipping Solutions designed <br />
                                            to save you money, time, and hassle.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} className='py-4 px-2 bg-white'>
                                <div>
                                    <div className='logo_box mx-auto'>
                                        <img src={require('../../Images/logo.png')} className='w-100 h-100 object-fit-contain' alt="" />
                                    </div>
                                    <div className='mt-4 text-center'>
                                        <h3 className='login_txt fw-bolder dark_clr mb-3'>
                                            Create your account
                                        </h3>
                                        <p className='m-0'>
                                            Start your 14-day free trial
                                        </p>
                                    </div>
                                    <div className='px-sm-5 px-3 mt-4'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            {/* Email Field */}
                                            <div className='my-3'>
                                                <span className='d-block mb-1'>Email</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.email && touched.email ? 'input_border' : ''}`}>
                                                        <Mail className='input_icns' />
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            className='input_controlls'
                                                            placeholder='Enter your email'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {errors.email && touched.email ? <span className='text-danger'>{errors.email}</span> : null}
                                                </div>
                                            </div>

                                            {/* Password Field */}
                                            <div className='my-3'>
                                                <span className='d-block mb-1'>Password</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.password && touched.password ? 'input_border' : ''}`}>
                                                        <CircleHelp className='input_icns' />
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={values.password}
                                                            className='input_controlls'
                                                            placeholder='Create a Password'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {errors.password && touched.password ? <span className='text-danger'>{errors.password}</span> : null}
                                                </div>
                                            </div>

                                            {/* Phone Number Field */}
                                            {/* <div className='my-3'>
                                                <span className='d-block mb-1'>Phone No.</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.phone && touched.phone ? 'input_border' : ''}`}>
                                                        <Phone className='input_icns' />
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            value={values.phone}
                                                            className='input_controlls'
                                                            placeholder='Enter Your Mobile No'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {errors.phone && touched.phone ? <span className='text-danger'>{errors.phone}</span> : null}
                                                </div>
                                            </div> */}

                                            {/* Company Name Field */}
                                            <div className='my-3'>
                                                <span className='d-block mb-1'>Company Name</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.company && touched.company ? 'input_border' : ''}`}>
                                                        <Building2 className='input_icns' />
                                                        <input
                                                            type="text"
                                                            name="company"
                                                            value={values.company}
                                                            className='input_controlls'
                                                            placeholder='Enter Company Name'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {errors.company && touched.company ? <span className='text-danger'>{errors.company}</span> : null}
                                                </div>
                                            </div>

                                            {/* Submit Button */}
                                            <div className='my-3'>
                                                <button
                                                    type='submit'
                                                    className='theme_btn w-100'
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        'Create Account'
                                                    )}
                                                </button>
                                            </div>

                                            {/* Sign In Link */}
                                            <div className='my-3 text-center mb-0'>
                                                <p className='m-0'>
                                                    Already have an account? <Link to={'/'} className='light_clr text-decoration-none'>Sign in</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default SignUp;
