import React, { useEffect, useState } from 'react';
import './SignIn.css';
import { Col, Row } from 'react-bootstrap';
import { CircleHelp, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SignIn = () => {

    const [loading, setLoading] = useState(false);

    const notify = (type) => {
        const message = type === 'error' ? "Email id not in our system!" : "Successfully logged in!";
        const backgroundColor = 'rgb(1, 27, 69)';
        const progressColor = 'linear-gradient(240deg, rgba(0, 184, 199, 0.1), rgba(1, 27, 69, 0.1))'; // Keep the same progress style
        const toastType = type === 'error' ? toast.error : toast.success;

        toastType(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                background: backgroundColor, // Dynamic background color
                color: '#fff', // White text
                borderRadius: '8px', // Rounded corners
                fontSize: '16px', // Adjust font size if needed
                padding: '12px 20px', // Adjust padding
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
            progressStyle: {
                background: progressColor, // Same gradient progress bar
            },
        });
    };

    // Validation Schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid Email Address")
            .required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required")
    });

    // Initial Values
    const initialValues = {
        email: '',
        password: ''
    };

    // Submit Function
    const submit = async (values, { resetForm }) => {
        try {
            setLoading(true);
            
            const response = await fetch('https://smart.lateshipment.com/Genericwebhook/usertoekenapi.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Key': process.env.REACT_APP_X_Auth_Key,
                    'X-Auth-email': process.env.REACT_APP_X_Auth_email,
                    'X-Auth-Customeremail': values.email,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate user');
            }

            const result = await response.json();

            if (result.status === 'success' && result.token !== 'Email id  not in our system') {

                const { token } = result;
                const timestamp = new Date().getTime();
                
                localStorage.setItem('userEmail', values.email);
                localStorage.setItem('userToken', token);
                localStorage.setItem('timestamp', timestamp.toString()); 

                // Redirect to LateShipment Dashboard
                const loginUrl = `https://apps.lateshipment.com/login/aflogin?lsemail=${values.email}&lstoken=${token}&af=dnFkenhLdGY5N0JrQzlERXVWa1g0Zz09`;

                window.location.href = loginUrl;

                resetForm();
                notify('success');
            } else {
                throw new Error('Invalid email or token');
            }
        } catch (error) {
            console.error('Login error:', error);
            notify('error');
        } finally {
            setLoading(false);  // Reset loading after request completion
        }
    };


    // Formik Hook
    const { handleBlur, handleChange, handleSubmit, touched, errors, values } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submit
    });

    return (
        <React.Fragment>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className='login_par'>
                <Row className='m-0 h-100 align-items-center justify-content-center'>
                    <Col xxl={8} xl={9} lg={10} md={6} sm={8} className='shadow-lg rounded-4 p-0 overflow-hidden'>
                        <Row className='m-0'>
                            <Col lg={7} className='d-lg-flex d-none p-0 theme_bg py-5 align-items-center'>
                                <div className='w-100'>
                                    <div className='img_box bg-white text-center mx-auto rounded-5 py-5 px-5'>
                                        <img src={require("../../Images/login_front.png")} className='w-100 cover' alt="" />
                                    </div>
                                    <div className='txt mt-5'>
                                        <p className='m-0 text-white text-center fw-bold f_18'>
                                            Shipping Solutions designed <br />
                                            to save you money, time, and hassle.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} className='py-3 px-2 bg-white'>
                                <div>
                                    <div className='logo_box mx-auto'>
                                        <img src={require('../../Images/logo.png')} className='w-100 h-100 object-fit-contain' alt="" />
                                    </div>
                                    <div className='mt-5 text-center'>
                                        <h3 className='login_txt fw-bolder dark_clr mb-3'>
                                            Welcome back
                                        </h3>
                                        <p className='m-0'>
                                            Please enter your details to sign in
                                        </p>
                                    </div>
                                    <div className='px-sm-5 px-3 mt-4'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            {/* Email Field */}
                                            <div className='my-3'>
                                                <span className='d-block mb-1'>Email</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.email && touched.email ? 'input_border' : ''}`}>
                                                        <Mail className='input_icns' />
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            className='input_controlls'
                                                            placeholder='Enter your email'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete="email"
                                                        />
                                                    </div>
                                                    {errors.email && touched.email ? <span className='text-danger'>{errors.email}</span> : null}
                                                </div>
                                            </div>

                                            {/* Password Field */}
                                            <div className='my-3'>
                                                <span className='d-block mb-1'>Password</span>
                                                <div>
                                                    <div className={`input_box d-flex align-items-center ${errors.password && touched.password ? 'input_border' : ''}`}>
                                                        <CircleHelp className='input_icns' />
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={values.password}
                                                            className='input_controlls'
                                                            placeholder='Enter your Password'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete="current-password"
                                                        />
                                                    </div>
                                                    {errors.password && touched.password ? <span className='text-danger'>{errors.password}</span> : null}
                                                </div>
                                            </div>

                                            {/* Remember Me & Forgot Password */}
                                            <div className='my-3'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='check'>
                                                        <input type="checkbox" name="remember" className='me-2' />
                                                        <span>Remember me</span>
                                                    </div>
                                                    <div className='check'>
                                                        <Link to={'/resetpassword'} className='text-decoration-none light_clr'>Forgot password?</Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='my-3'>
                                                <button
                                                    type='submit'
                                                    className='theme_btn w-100'
                                                    disabled={loading} // Disable button while loading
                                                >
                                                    {loading ? (
                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        'Sign in'
                                                    )}
                                                </button>
                                            </div>

                                            {/* Sign Up Link */}
                                            <div className='my-3 text-center'>
                                                <p className='m-0'>
                                                    Don't have an account? <Link to={'/signup'} className='light_clr text-decoration-none'>Sign up</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default SignIn;
